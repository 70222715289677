.documentdiv{
    text-align: left !important;
    margin-left: 2em;
    margin-top: 1em;
}
.divtitle{
    font-size: 2em;
    color:brown;
    font-weight: bold;
}
.divdocumentrow{
    padding-bottom: 4px;
   border-bottom: 2px dashed gainsboro;
}
.documenttitleStyle{
    font-size: 0.8em;
    color: rgba(160, 160, 160, 0.954);
    margin-top: 6px;
    margin-left: 6px;
    text-align: left;
}
.documentbriefStyle{
    font-size: 0.8em;
    color: rgba(19, 19, 19, 0.954);
    margin-top: 6px;
    margin-left: 6px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    overflow:hidden;
     min-height: 40px;
}
.documentDateStyle{
    font-size: 0.5em;
    color: rgba(147, 147, 147, 0.954);
    margin-top: 6px;
    margin-left: 6px;
    text-align: left;
}
.documentLabelcls{
    font-size: 0.8em;
   
}