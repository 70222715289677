
.carouseDivStyle{
    padding-top: 50px;
    height: 350px;
}
.contentStyle {
    height: 320px;
    color: #fff;
    line-height: 160px;
    text-align: center;
    background: #ffffff00;
    
  };

