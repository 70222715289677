.bodyStyle{
    margin-top:0em;
}
.ocrListStyle{
    font-size: 1em;
}
.ocrDividerStyle{
    color: rgb(108, 186, 186) !important;
    border-top: 3px !important;
    border-color: cornflowerblue !important;
}
.ocrDivStyle{
    padding-left: 4em;
    padding-right: 4em;
    margin-top: 20px;
}
.ocrTextStyle{
    font-size: 1em;
    font-weight:bold;
    color: dimgray;
    margin-top: 10px;
}
.faceListStyle{
    font-size: 1em;
}
.faceDividerStyle{
    color: rgb(108, 186, 186) !important;
    border-top: 3px !important;
    border-color: cornflowerblue !important;
}
.faceDivStyle{
    padding-left: 4em;
    padding-right: 4em;
}

.faceTextStyle{
    font-size: 1em;
    font-weight:bold;
    color: dimgray;
    margin-top: 10px;
}