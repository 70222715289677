.imagename{
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.imageborder{
    background-color: rgb(193, 193, 193);
    padding: 3px;
}
.panelcls{
    text-align: left;
}