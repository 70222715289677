.bodyStyle{
    margin-top:0em;
}
.ocrListStyle{
    font-size: 1em;
}
.ocrDividerStyle{
    color: rgb(108, 186, 186) !important;
    border-top: 3px !important;
    border-color: cornflowerblue !important;
}
.ocrDivStyle{
    padding-left: 4em;
    padding-right: 4em;
    margin-top: 20px;
}
.ocrTextStyle{
    font-size: 1em;
    font-weight:bold;
    /* color: dimgray; */
    margin-top: 10px;
    margin-left: 1em;
    text-align: left;
}
.faceListStyle{
    font-size: 1em;
}
.faceDividerStyle{
    color: rgb(108, 186, 186) !important;
    border-top: 3px !important;
    border-color: cornflowerblue !important;
}
.faceDivStyle{
    padding-left: 4em;
    padding-right: 4em;
}

.faceTextStyle{
    font-size: 1em;
    font-weight:bold;
    color: dimgray;
    margin-top: 10px;
}

.documenttitleStyle{
    font-size: 0.8em;
    color: rgba(160, 160, 160, 0.954);
    margin-top: 6px;
    margin-left: 6px;
    text-align: left;
}
.documentbriefStyle{
    font-size: 0.8em;
    color: rgba(19, 19, 19, 0.954);
    margin-top: 6px;
    margin-left: 6px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    overflow:hidden;
     min-height: 40px;
}
.documentDateStyle{
    font-size: 0.5em;
    color: rgba(147, 147, 147, 0.954);
    margin-top: 6px;
    margin-left: 6px;
    text-align: left;
}
.documentLabelcls{
    font-size: 0.8em;
   
}
.divdocumentrow{
    padding-bottom: 4px;
   border-bottom: 2px dashed gainsboro;
}