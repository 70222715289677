.moneycls{
    /* font-size: 2em; */
    color: crimson;
    font-weight: bold;
}
.titlecls{
    text-align: right;
    font-weight: bold;
}
.contentcls{
    text-align: left;
}
.paytitle{
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
    margin-top: 1em;
}
.weixinpaytitle{
    font-size: 1em;
    font-weight: bold;
    color: green;
    margin-top: 1em;
    margin-bottom: 0.7em;
}