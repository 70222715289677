.contenttxt{
    margin-top: 2em;
    margin-left: 2em;
    text-align: left;
}
.contenttxtdiv{
    text-align: left;
    font-size: 0.5em;
}
.contentnamediv{
    font-size: 1.5em;
    font-weight: bold;
}
.skuimagecol{
    text-align: right;
}
.skuimage{
    margin-top: 2em;
    margin-right: 2em;
    width:10em;
    height: 10em; 
}
.skuprice{
    color: crimson;
    font-weight: bold;
}