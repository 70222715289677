.menusdiv{
    background-color:#000000d1 !important;
    width: 100%;
    height: 3.4em;
}
.menustyle {
    background-color:#0b0b0b00 !important;
    border-color: #00000000;
    float: right;
    width: 28em;
    margin-top: 0%;
    color: aliceblue !important;
    font-size: 1em;
    /* line-height:70px; */
}
.ant-menu-horizontal > .ant-menu-item-selected a{
    color: #1890ff !important;
}
.ant-menu-horizontal > .ant-menu-item a{
    color: rgba(255, 255, 255, 0.696)
}
.carouseDivStyle{
    padding-top: 10px;
}
.contentStyle {
    height: 320px;
    color: #fff;
    line-height: 160px;
    text-align: center;
    background: #ffffff00;
    
  };

